import { NAVBAR_PATHS } from '../../../../utils/constants/Paths'
import { CONTENT } from '../../../../utils/mockData/homeContent'

export const Lecture = () => {
  return (
    <section
      id={NAVBAR_PATHS.lecture}
      className="row align-items-center justify-content-center p-5 m-0"
      style={{ backgroundImage: 'url(/assets/jac-bg.png)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
    >
      <div className="col-10 col-sm-8 col-lg-6">
        <img
          src={`${CONTENT.lecture.image.src}`}
          className="img-fluid shadow rounded-circle"
          alt={CONTENT.lecture.image.alt}
          width="700"
          height="500"
          loading="lazy"
        />
      </div>
      <div className="col-lg-6 mt-3 mt-lg-0">
        <h3 className="fs-3">{CONTENT.lecture.title}</h3>
        {
          CONTENT.lecture.paragraphs.map(({ text, list, id }) => {
            return (
              !list || !list.length ? (
                <p className="lead" key={id} dangerouslySetInnerHTML={{ __html: text }}></p>
              ) : (
                <ul key={id}>
                  {list.map(({ id, text }) => (
                    <li key={id}>{text}</li>
                  ))}
                </ul>
              )
            )
          })
        }
      </div>
    </section>
  )
}