import { NAVBAR_PATHS } from "../../../../utils/constants/Paths"

export const Stands = () => {
  return (
    <section className="d-flex flex-column justify-content-center align-items-center gap-4" id={NAVBAR_PATHS.stands}>
      {/* Balcões */}
      <div className="d-flex w-100 gap-3 justify-content-between p-5 flex-wrap">

        <div className="d-flex gap-5 flex-wrap justify-content-center flex-grow-1">
          <div className="w-100">
            <span className="rounded-5 px-4 py-2 text-primary bg-warning fs-3">Balcões</span>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img className="img-fluid" style={{ minHeight: '325px' }} src="/assets/balcao.png" alt="Balcão" />
            <ul className="list-unstyled fw-bold text-center">
              <li className="chip"> Área de 1m² (1x1m) </li>
              <li className="chip"> Balcões de A a R </li>
            </ul>
          </div>
        </div>

        <div className="d-flex flex-column flex-grow-1 gap-4">
          <div className="bg-primary p-4 rounded-2 shadow">
            <p className="text-warning fw-bold"> Inclusos: </p>
            <p className="fw-bold text-white"> Acesso ao banco de dados gerado em todo o evento. </p>
            <p className="fw-bold text-white"> Logo da empresa em todas as divulgações. </p>
            <span className="text-white">(ex: redes sociais, sites e comunicadores da organização da feira.)</span>
          </div>

          <div className="bg-primary-subtle p-4 rounded-2 shadow">
            <p className="text-danger fw-bold"> Sugestão de layout: </p>
            <ul className="d-flex gap-5 text-danger chip">
              <li>Balcão 1x1</li>
              <li>1 Cadeira</li>
            </ul>
            <span className="text-danger">Sugestão de mobiliário não incluso no contrato.</span>
          </div>
        </div>

        {/* <div className="w-100 bg-warning p-3 rounded shadow mt-3">
          <ul className="fs-3 text-primary chip fw-bold text-uppercase m-0">
            <li className="d-flex justify-content-center align-items-center gap-3">1º lote <span className="d-block bg-primary" style={{ height: '2px', width: '75px' }}></span> R$ 1.200,00 </li>
            <li className="d-flex justify-content-center align-items-center gap-3">2º lote <span className="d-block bg-primary" style={{ height: '2px', width: '75px' }}></span> R$ 1.460,00 </li>
            <li className="d-flex justify-content-center align-items-center gap-3">3º lote <span className="d-block bg-primary" style={{ height: '2px', width: '75px' }}></span> R$ 1.686,00 </li>
          </ul>
        </div>
        <ul className="list-unstyled text-dark fw-bold chip">
          <li>1º lote de 16 de outubro a 31 de outubro</li>
          <li>2º lote de 01 de novembro a 15 de novembro</li>
          <li>3º lote de 16 de novembro a 26 de novembro </li>
        </ul> */}

      </div>
      <div className="bg-danger" style={{ width: '90%', height: '1px' }}></div>
      {/* Stand Azul */}
      <div className="d-flex w-100 gap-3 justify-content-between p-5 flex-wrap">

        <div className="d-flex gap-5 flex-wrap justify-content-center flex-grow-1">
          <div className="w-100">
            <span className="rounded-5 px-4 py-2 text-primary bg-warning fs-3">Stand Azul</span>
          </div>

          <div className="d-flex flex-column justify-content-center align-items-center">
            <img className="img-fluid" style={{ minHeight: '325px' }} src="/assets/stand-azul.png" alt="Stand Azul" />
            <ul className="list-unstyled fw-bold text-center">
              <li className="chip"> Área de 4m² (2x2m) </li>
              <li className="chip"> 2, 5 </li>
            </ul>
          </div>
        </div>

        <div className="d-flex flex-column flex-grow-1 gap-4">
          <div className="bg-primary p-4 rounded-2 shadow">
            <p className="text-warning fw-bold"> Inclusos: </p>
            <p className="fw-bold text-white"> Acesso ao banco de dados gerado em todo o evento. </p>
            <p className="fw-bold text-white"> Logo da empresa em todas as divulgações. </p>
            <span className="text-white">(ex: redes sociais, sites e comunicadores da organização da feira.)</span>
          </div>

          <div className="bg-primary-subtle p-4 rounded-2 shadow">
            <p className="text-danger fw-bold"> Sugestão de layout: </p>
            <ul className="d-flex gap-5 text-danger chip">
              <li>Estande 2x2 montado</li>
              <li>1 mesa com 3 cadeiras</li>
            </ul>
            <span className="text-danger">Sugestão de mobiliário não incluso no contrato.</span>
          </div>
        </div>

        {/* <div className="w-100 bg-warning p-3 rounded shadow mt-3">
          <ul className="fs-3 text-primary chip fw-bold text-uppercase m-0">
            <li className="d-flex justify-content-center align-items-center gap-3">1º lote <span className="d-block bg-primary" style={{ height: '2px', width: '75px' }}></span> R$ 2.800,00 </li>
            <li className="d-flex justify-content-center align-items-center gap-3">2º lote <span className="d-block bg-primary" style={{ height: '2px', width: '75px' }}></span> R$ 2.940,00 </li>
            <li className="d-flex justify-content-center align-items-center gap-3">3º lote <span className="d-block bg-primary" style={{ height: '2px', width: '75px' }}></span> R$ 3.234,00 </li>
          </ul>
        </div>
        <ul className="list-unstyled text-dark fw-bold chip">
          <li>1º lote de 16 de outubro a 31 de outubro</li>
          <li>2º lote de 01 de novembro a 15 de novembro</li>
          <li>3º lote de 16 de novembro a 26 de novembro </li>
        </ul> */}

      </div>
      <div className="bg-danger" style={{ width: '90%', height: '1px' }}></div>
      {/* Stand Laranja */}
      <div className="d-flex w-100 gap-3 justify-content-between p-5 flex-wrap">

        <div className="d-flex gap-5 flex-wrap justify-content-center flex-grow-1">
          <div className="w-100">
            <span className="rounded-5 px-4 py-2 text-primary bg-warning fs-3">Stand Laranja</span>
          </div>

          <div className="d-flex flex-column justify-content-center align-items-center">
            <img className="img-fluid" style={{ minHeight: '325px' }} src="/assets/stand-laranja.png" alt="Stand Laranja" />
            <ul className="list-unstyled fw-bold text-center">
              <li className="chip"> Área de 6m² (3x2m) </li>
              <li className="chip"> 1, 3, 4, 6, 7, 22 a 28 </li>
            </ul>
          </div>
        </div>

        <div className="d-flex flex-column flex-grow-1 gap-4">
          <div className="bg-primary p-4 rounded-2 shadow">
            <p className="text-warning fw-bold"> Inclusos: </p>
            <p className="fw-bold text-white"> Acesso ao banco de dados gerado em todo o evento. </p>
            <p className="fw-bold text-white"> Logo da empresa em todas as divulgações. </p>
            <span className="text-white">(ex: redes sociais, sites e comunicadores da organização da feira.)</span>
          </div>

          <div className="bg-primary-subtle p-4 rounded-2 shadow">
            <p className="text-danger fw-bold"> Sugestão de layout: </p>
            <ul className="d-flex gap-5 text-danger chip">
              <li>Estande 3x2 montado</li>
              <li>1 mesa com 3 cadeiras</li>
            </ul>
            <span className="text-danger">Sugestão de mobiliário não incluso no contrato.</span>
          </div>
        </div>

        {/* <div className="w-100 bg-warning p-3 rounded shadow mt-3">
          <ul className="fs-3 text-primary chip fw-bold text-uppercase m-0">
            <li className="d-flex justify-content-center align-items-center gap-3">1º lote <span className="d-block bg-primary" style={{ height: '2px', width: '75px' }}></span> R$ 3.900,00 </li>
            <li className="d-flex justify-content-center align-items-center gap-3">2º lote <span className="d-block bg-primary" style={{ height: '2px', width: '75px' }}></span> R$ 4.095,00 </li>
            <li className="d-flex justify-content-center align-items-center gap-3">3º lote <span className="d-block bg-primary" style={{ height: '2px', width: '75px' }}></span> R$ 4.504,50 </li>
          </ul>
        </div>
        <ul className="list-unstyled text-dark fw-bold chip">
          <li>1º lote de 16 de outubro a 31 de outubro</li>
          <li>2º lote de 01 de novembro a 15 de novembro</li>
          <li>3º lote de 16 de novembro a 26 de novembro </li>
        </ul> */}

      </div>
      <div className="bg-danger" style={{ width: '90%', height: '1px' }}></div>
      {/* Stand Amarelo */}
      <div className="d-flex w-100 gap-3 justify-content-between p-5 flex-wrap">

        <div className="d-flex gap-5 flex-wrap justify-content-center flex-grow-1">
          <div className="w-100">
            <span className="rounded-5 px-4 py-2 text-primary bg-warning fs-3">Stand Amarelo</span>
          </div>

          <div className="d-flex flex-column justify-content-center align-items-center">
            <img className="img-fluid" style={{ minHeight: '325px' }} src="/assets/stand-amarelo.png" alt="Stand Amarelo" />
            <ul className="list-unstyled fw-bold text-center">
              <li className="chip"> Área de 9m² (3x3m) </li>
              <li className="chip"> 8 a 21 </li>
            </ul>
          </div>
        </div>

        <div className="d-flex flex-column flex-grow-1 gap-4">
          <div className="bg-primary p-4 rounded-2 shadow">
            <p className="text-warning fw-bold"> Inclusos: </p>
            <p className="fw-bold text-white"> Acesso ao banco de dados gerado em todo o evento. </p>
            <p className="fw-bold text-white"> Bate-papo no palco Freguesia em Ação. </p>
            <p className="fw-bold text-white"> Marca no painel de led e telão do palco. </p>
            <p className="fw-bold text-white"> Entrevista no stand com o microfone volante. </p>
            <p className="fw-bold text-white"> Entrevista de 30 minutos no som principal.  </p>
            <p className="fw-bold text-white"> Logo da empresa em todas as mídias de vinculação inclusive com exclusividade nas mídias do Shopping Rio Office Mall  como apoiadores localizados em:  </p>
            <div className="d-flex flex-wrap gap-4 text-white">
              <ul style={{ minWidth: '200px' }}>
                <li>Display das cancelas</li>
                <li>Backlight praça</li>
                <li>Elevadores</li>
                <li>Escadas rolantes</li>
              </ul>
              <ul style={{ minWidth: '200px' }}>
                <li>Mesas da praça de alimentação</li>
                <li>Cancelas do estacionamento</li>
              </ul>
              <ul style={{ minWidth: '200px' }}>
                <li>Espelhos dos banheiros</li>
                <li>Porta dos banheiros</li>
                <li>Displays de mall</li>
              </ul>
            </div>
          </div>

          <div className="bg-primary-subtle p-4 rounded-2 shadow">
            <p className="text-danger fw-bold"> Sugestão de layout: </p>
            <ul className="d-flex gap-5 text-danger chip flex-wrap">
              <li>Estande 3x3 montado</li>
              <li>1 mesa com 4 cadeiras</li>
              <li>1 balcão</li>
              <li>1 sofá</li>
            </ul>
            <span className="text-danger">Sugestão de mobiliário não incluso no contrato.</span>
          </div>
        </div>

        {/* <div className="w-100 bg-warning p-3 rounded shadow mt-3">
          <ul className="fs-3 text-primary chip fw-bold text-uppercase m-0">
            <li className="d-flex justify-content-center align-items-center gap-3">1º lote <span className="d-block bg-primary" style={{ height: '2px', width: '75px' }}></span> R$ 5.310,00 </li>
            <li className="d-flex justify-content-center align-items-center gap-3">2º lote <span className="d-block bg-primary" style={{ height: '2px', width: '75px' }}></span> R$ 5.575,50 </li>
            <li className="d-flex justify-content-center align-items-center gap-3">3º lote <span className="d-block bg-primary" style={{ height: '2px', width: '75px' }}></span> R$ 6.133,05 </li>
          </ul>
        </div>
        <ul className="list-unstyled text-dark fw-bold chip">
          <li>1º lote de 16 de outubro a 31 de outubro</li>
          <li>2º lote de 01 de novembro a 15 de novembro</li>
          <li>3º lote de 16 de novembro a 26 de novembro </li>
        </ul> */}

      </div>
      <div className="bg-danger" style={{ width: '90%', height: '1px' }}></div>
      {/* Panfletagem */}
      <div className="d-flex w-100 gap-3 justify-content-between p-5 flex-wrap">

        <div className="d-flex gap-5 flex-wrap justify-content-center flex-grow-1">
          <div className="w-100">
            <span className="rounded-5 px-4 py-2 text-primary bg-warning fs-3">Panfletagem</span>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img className="img-fluid" style={{ minHeight: '325px' }} src="/assets/panfletagem.png" alt="Panfletagem" />
            </div>
          </div>
        </div>

        <div className="d-flex flex-column flex-grow-1 gap-4">
          <div className="bg-primary p-4 rounded-2 shadow">
            <p className="fw-bold text-white m-0">
              Usaremos o estilo para os micro empreendedores locais que estão interessados no evento em prol de colaboração, apoio social e inclusão no projeto com a sociedade.
            </p>
          </div>
        </div>

        {/* <div className="w-100 bg-warning p-3 rounded shadow mt-3">
          <ul className="fs-3 text-primary chip fw-bold text-uppercase m-0">
            <li className="d-flex justify-content-center align-items-center gap-3">1º lote <span className="d-block bg-primary" style={{ height: '2px', width: '75px' }}></span> R$ 600,00 </li>
            <li className="d-flex justify-content-center align-items-center gap-3">2º lote <span className="d-block bg-primary" style={{ height: '2px', width: '75px' }}></span> R$ 800,00 </li>
            <li className="d-flex justify-content-center align-items-center gap-3">3º lote <span className="d-block bg-primary" style={{ height: '2px', width: '75px' }}></span> R$ 1.000,00 </li>
          </ul>
        </div>
        <ul className="list-unstyled text-dark fw-bold chip">
          <li>1º lote de 16 de outubro a 31 de outubro</li>
          <li>2º lote de 01 de novembro a 15 de novembro</li>
          <li>3º lote de 16 de novembro a 26 de novembro </li>
        </ul> */}

      </div>
      <div className="bg-danger" style={{ width: '90%', height: '1px' }}></div>
      {/* Mapa Stands */}
      <div className="d-flex w-100 gap-3 justify-content-between p-5 flex-wrap">

        <div className="d-flex gap-5 flex-wrap justify-content-center flex-grow-1">
          <div className="w-100">
            <span className="rounded-5 px-4 py-2 text-primary bg-warning fs-3">Mapa de Stands e Balcões</span>
            <div className="w-100 mt-5 d-flex justify-content-center align-items-center">
              <img className="text-center img-fluid shadow rounded-2" src="/assets/mapa-stands.png" alt="Mapa Stands" />
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}