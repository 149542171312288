import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NAVBAR_PATHS } from "../../../../utils/constants/Paths"

export const Intro = () => {
  return (
    <>
      <section id={NAVBAR_PATHS.intro} className="min-vh-100 d-flex flex-column w-100">
        <img className="w-100" src="/assets/capa-expocond.png" alt="Capa Expo Cond" />
        <div className="flex-grow-1 bg-primary p-5 d-flex justify-content-center align-items-center flex-wrap">
          <div className="d-flex w-100 fs-5 gap-5 flex-column">
            <p className="text-white text-center">
              A ExpoCond Jacarepaguá, vai reunir os principais fornecedores de cada segmento, para apresentar suas soluções para um grupo seleto de síndicos em ambiente presencial.
            </p>
            <p className="text-white text-center">
              + de 46 marcas expositoras, com participação confirmada de + de 400 síndicos
            </p>
          </div>
          <div className="d-flex flex-grow-1 w-100 justify-content-center">
            <p className="text-uppercase fw-bold fs-4 text-warning">Trazendo o melhor para Jacarepaguá!</p>
          </div>
        </div>
      </section>
      <section className="d-flex justify-content-center align-items-center bg-primary py-5" style={{ backgroundImage: "url('/assets/fundo.png')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
        <div className="d-flex justify-content-center align-items-center gap-4 flex-wrap m-5">
          <div className="chip bg-warning text-center p-4 rounded-5 mx-3 shadow my-3">
            <p className="text-primary fw-bold text-uppercase mb-0 fs-4"> 1 de dezembro de 2023 </p>
            <p className="text-primary fw-bold text-uppercase mb-0 fs-2"> 14h às 22h </p>
          </div>
          <div className="chip bg-warning text-center p-4 rounded-5 mx-3 shadow my-3">
            <p className="text-primary fw-bold text-uppercase mb-0 fs-4"> 2 de dezembro de 2023 </p>
            <p className="text-primary fw-bold text-uppercase mb-0 fs-2"> 10h às 20h </p>
          </div>
        </div>
      </section>
      <section className="bg-primary p-5 d-flex justify-content-center align-items-center flex-wrap gap-5">
        <div className="flex-1">
          <a
            className="text-decoration-none d-flex w-100 fs-1 text-white text-uppercase justify-content-center align-items-center"
            href="https://www.google.com.br/maps/place/Rio+Office+%26+Mall/@-22.943552,-43.3476557,17z/data=!3m1!4b1!4m6!3m5!1s0x9bd9cb2774aef9:0x92852323bb023346!8m2!3d-22.943557!4d-43.3450808!16s%2Fm%2F042zgyp?entry=ttu"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon className="text-warning mx-3" icon={['fas', 'location-pin']} />
            <h2 className="hover-underline fw-bold">
              Rio Office & Mall
            </h2>
          </a>
          <ul className="list-unstyled text-center">
            <li className="text-white text-uppercase fw-bold">Estr. do Gabinal, 313</li>
            <li className="text-warning text-uppercase fw-bold">Freguesia - JPA</li>
          </ul>
        </div>
        <img src="/assets/fundo-2.jpeg" alt="Fundo 2" className="img-fluid shadow rounded-circle" style={{ maxHeight: '500px' }} />
      </section>
    </>
  )
}