import { NAVBAR_PATHS } from '../../../../utils/constants/Paths'
import { CONTENT } from '../../../../utils/mockData/homeContent'

export const Schedule = () => {
  return (
    <section className="d-flex bg-primary p-5 gap-5 justify-content-between flex-wrap" id={NAVBAR_PATHS.schedule}>
      <h2 className="text-center w-100 text-warning"> {CONTENT.schedule.title} </h2>

      {
        CONTENT.schedule.dates.map(({ day, events, id }) => (
          <div className="flex-1" style={{ minWidth: '300px', maxWidth: '40%' }} key={id}>
            <span className="rounded-5 px-4 py-2 text-primary bg-warning fs-3 chip">{day}</span>
            {
              events.map(({ id, hour, label }) => (
                <div className="d-flex gap-3 mt-4" key={id}>
                  <p className="text-warning fs-4 fw-bold chip">
                    {hour}
                  </p>
                  <p className="text-white fs-5">
                    {label}
                  </p>
                </div>
              ))
            }
          </div>
        ))
      }

    </section>
  )
}