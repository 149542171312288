import { NAVBAR_PATHS } from '../../../../utils/constants/Paths'
import { CONTENT } from '../../../../utils/mockData/homeContent'

export const Presentation = () => {
  return (
    <section
      id={NAVBAR_PATHS.presentation}
      className="row align-items-center justify-content-center p-5 m-0"
      style={{ backgroundImage: 'url(/assets/jac-bg.png)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
    >
      <div className="col-lg-6 mt-3 mt-lg-0">
        <div className="w-100 d-flex justify-content-center align-items-center">
          <img src={CONTENT.presentation.imageTitle.src} alt={CONTENT.presentation.imageTitle.alt} style={{ maxWidth: '100%', maxHeight: '220px' }} />
        </div>
        <h3 className="fs-3 fw-bold">{CONTENT.presentation.title}</h3>
        {
          CONTENT.presentation.paragraphs.map(({ text, id }) => (
            <p key={id} dangerouslySetInnerHTML={{ __html: text }}></p>
          ))
        }
      </div>
      <div className="col-10 col-sm-8 col-lg-6">
        <img
          src={`${CONTENT.presentation.image.src}`}
          className="img-fluid shadow rounded-circle"
          alt={CONTENT.presentation.image.alt}
          width="700"
          height="500"
          loading="lazy"
        />
      </div>
    </section>
  )
}