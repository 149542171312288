import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NAVBAR_PATHS } from '../../utils/constants/Paths';
import { CONTENT } from '../../utils/mockData/homeContent';

export const GenericNavbar = () => {
  return (
    <header>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand>
            <img src="/assets/logo-expocond.png" alt="Logo Expo Cond" width="60" height="60" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href={`#${NAVBAR_PATHS.intro}`} className="nav-link">
                {CONTENT.navbar.intro}
              </Nav.Link>
              <Nav.Link href={`#${NAVBAR_PATHS.presentation}`} className="nav-link">
                {CONTENT.navbar.presentation}
              </Nav.Link>
              <Nav.Link href={`#${NAVBAR_PATHS.schedule}`} className="nav-link">
                {CONTENT.navbar.schedule}
              </Nav.Link>
              <Nav.Link href={`#${NAVBAR_PATHS.stands}`} className="nav-link">
                {CONTENT.navbar.stands}
              </Nav.Link>
              <Nav.Link href={`#${NAVBAR_PATHS.partners}`} className="nav-link">
                {CONTENT.navbar.partners}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};
