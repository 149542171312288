export const CONTENT = {
  navbar: {
    intro: 'Apresentação',
    presentation: 'Atrações',
    schedule: 'Programação',
    stands: 'Balcões & Stands',
    partners: 'Expositores confimados'
  },
  presentation: {
    imageTitle: {
      src: `/assets/freguesia-em-acao.png`,
      alt: 'Freguesia em Ação'
    },
    image: {
      src: `/assets/fundo-3.png`,
      alt: 'Fundo 3'
    },
    title: 'Palco Freguesia em Ação',
    paragraphs: [
      {
        id: 1,
        text: 'O <span class="fw-bold">PALCO AÇÃO</span> será administrado pela equipe do <span class="fw-bold">Freguesia em Ação</span>, <span class="fw-bold">Ricardo Sá</span> e <span class="fw-bold">Nidi Barreto</span>, a página no Instagram é a maior rede de comunicação da Freguesia, nela estarão presentes também influenciadores de Jacarepaguá e representantes de páginas parceiras.'
      },
      {
        id: 2,
        text: 'Teremos muita conectividade com os expositores, contando  com seu personagem icônico: <span class="fw-bold">O Microfone em Ação</span>, levando informações sobre stands para o som da feira, expondo todos benefícios e produtos dos expositores dos stands.'
      }
    ],
    button_label: 'Saiba mais'
  },
  lecture: {
    image: {
      src: `/assets/lecture.jpg`,
      alt: 'Palestra'
    },
    title: 'Palestras',
    paragraphs: [
      {
        id: 1,
        text: 'Teremos um circuito de palestras nos dias <span class="fw-bold">01</span> e <span class="fw-bold">02</span> de dezembro no auditório da <span class="fw-bold">Faculdade Cândido Mendes</span> para 100 pessoas por sessão. Os temas serão variados, atuais, engajados e administrados pelo <span class="fw-bold">Grupo Reinventta Cursos</span>.'
      },
      {
        id: 2,
        list: [
          {
            id: 21,
            text: 'Sustentabilidade em Condomínios;'
          },
          {
            id: 22,
            text: 'A obrigatoriedade da SST (Saúde e Segurança do Trabalho) nos Condomínios;'
          },
          {
            id: 23,
            text: 'Segurança em condomínio;'
          },
          {
            id: 24,
            text: 'Inovações tecnológicas para condomínio;'
          },
          {
            id: 25,
            text: 'Convivência e mediação de conflitos;'
          },
          {
            id: 26,
            text: 'Responsabilidades do Síndico e da Administradora.'
          },
        ]
      }
    ],
  },
  schedule: {
    title: 'Programação',
    dates: [
      {
        id: 0,
        day: '01/12',
        events: [
          {
            id: 10,
            hour: '14h00',
            label: 'Início credenciamento/visitação área de exposição.'
          },
          {
            id: 11,
            hour: '15h30',
            label: 'Abertura Oficial do Evento - Carlos Sampaio.'
          },
          {
            id: 12,
            hour: '15h55',
            label: 'Painel: Inovações tecnológicas em Sustentabilidade para Condomínios - André Esteves.'
          },
          {
            id: 13,
            hour: '16h40',
            label: 'Intervalo/Visitação área de exposição.'
          },
          {
            id: 14,
            hour: '17h20',
            label: 'Painel: Síndico Morador x Síndico Profissional.'
          },
          {
            id: 32989,
            hour: '18h10',
            label: 'Painel: Dicas de segurança - Delegado titulos da 32ª DP.'
          },
          {
            id: 329842,
            hour: '19h00',
            label: 'Painel: Responsabilidades do Síndico e da Administradora - Jean Carlos e Carlos Sampaio.'
          },
          {
            id: 15,
            hour: '20h00',
            label: 'Intervalo/Visitação área de exposição.'
          },
          {
            id: 16,
            hour: '20h30',
            label: 'Happy hour e rodada de negócios/visitação à área de exposição.'
          },
        ]
      },
      {
        id: 1,
        day: '02/12',
        events: [
          {
            id: 101,
            hour: '10h00',
            label: 'Início credenciamento/visitação área de exposição.'
          },
          {
            id: 110,
            hour: '10h50',
            label: 'Abertura Oficial do Evento - Carlos Sampaio.'
          },
          {
            id: 120,
            hour: '11h15',
            label: 'Painel: Inovações tecnológicas para condomínios.'
          },
          {
            id: 130,
            hour: '12h00',
            label: 'Intervalo/Visitação área de exposição'
          },
          {
            id: 140,
            hour: '13h40',
            label: 'A Obrigatoriedade da SST (Saúde e segurança do Trabalho).'
          },
          {
            id: 141,
            hour: '14h30',
            label: 'Treinamento CIPA.'
          },
          {
            id: 150,
            hour: '15h20',
            label: 'Convivência e mediação de conflitos.'
          },
          {
            id: 160,
            hour: '16h20',
            label: 'Intervalo/Visitação área de exposição.'
          },
          {
            id: 170,
            hour: '16h40',
            label: 'Fui eleito a Síndico, o que fazer?'
          },
          {
            id: 180,
            hour: '17h25',
            label: 'Segurança em condomínio.'
          },
          {
            id: 190,
            hour: '19h00 às 22h',
            label: 'Happy hour, rodada de negócios, sorteios e finalização.'
          },
        ]
      }
    ]
  }
}