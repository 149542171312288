import { GenericNavbar } from '../../components/GenericNavbar'
import { Presentation } from './components/Presentation'
import { GenericFooter } from '../../components/GenericFooter'
import { Intro } from './components/Intro'
import { Lecture } from './components/Lecture'
import { Schedule } from './components/Schedule'
import { Stands } from './components/Stands'
import { Partners } from './components/Partners'
import { WppButton } from '../../components/WppButton'

export const Home = () => {
  return (
    <>
      <GenericNavbar />
      <Intro />
      <div style={{ transform: 'translateY(-5px) rotate(180deg)' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#206a3b" fillOpacity="1" d="M0,224L48,234.7C96,245,192,267,288,266.7C384,267,480,245,576,224C672,203,768,181,864,186.7C960,192,1056,224,1152,234.7C1248,245,1344,235,1392,229.3L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
      </div>
      <Presentation />
      <Lecture />
      <div style={{ transform: 'translateY(5px)' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#206a3b" fillOpacity="1" d="M0,224L48,234.7C96,245,192,267,288,266.7C384,267,480,245,576,224C672,203,768,181,864,186.7C960,192,1056,224,1152,234.7C1248,245,1344,235,1392,229.3L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
      </div>
      <Schedule />
      <div style={{ transform: 'translateY(-5px) rotate(180deg)' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#206a3b" fillOpacity="1" d="M0,64L60,85.3C120,107,240,149,360,144C480,139,600,85,720,69.3C840,53,960,75,1080,85.3C1200,96,1320,96,1380,96L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
      </div>
      <Stands />
      <div style={{ transform: 'translateY(2px)' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#206a3b" fillOpacity="1" d="M0,64L60,85.3C120,107,240,149,360,144C480,139,600,85,720,69.3C840,53,960,75,1080,85.3C1200,96,1320,96,1380,96L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
      </div>
      <Partners />
      <div style={{ transform: 'rotate(180deg) translateY(5px)' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#206a3b" fillOpacity="1" d="M0,64L60,85.3C120,107,240,149,360,144C480,139,600,85,720,69.3C840,53,960,75,1080,85.3C1200,96,1320,96,1380,96L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
      </div>
      <GenericFooter />
      <WppButton />
    </>
  )
}