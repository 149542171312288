import Card from 'react-bootstrap/Card'
import { LINKS } from '../../../../utils/mockData/links'
import { openPage } from '../../../../utils/helpers/window'
import { NAVBAR_PATHS } from '../../../../utils/constants/Paths'

export const Partners = () => {
  const CARD_WIDTH = '300px'
  const CARD_MIN_HEIGHT = '350px'
  const CARD_HEIGHT = '350px'

  return (
    <section className="bg-primary text-white p-5 d-flex flex-wrap" id={NAVBAR_PATHS.partners}>
      <h2 className="fw-bold fs-2 text-warning w-100 text-center"> Expositores confimados </h2>
      <div className="w-100 p-3 flex-wrap d-flex justify-content-center align-items-center">
        <Card
          className="p-3 shadow"
          style={{ minWidth: CARD_WIDTH, width: CARD_WIDTH, minHeight: CARD_MIN_HEIGHT, height: CARD_HEIGHT, maxHeight: CARD_HEIGHT, cursor: 'pointer' }}
          onClick={() => openPage(LINKS.instagram)}
        >
          <Card.Img
            variant="top"
            src={`/assets/logo-expocond.png`}
            className={'img-fluid'}
            width="168"
            height="168"
          />
          <Card.Title className="mt-2 text-center mt-5">{'Clique aqui!'}</Card.Title>
        </Card>
      </div>
    </section>
  )
}