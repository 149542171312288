import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'react-bootstrap/Button'
import { LINKS } from '../../utils/mockData/links'
import { openPage } from '../../utils/helpers/window'

export const GenericFooter = () => {
  return (
    <footer className="d-flex flex-column align-items-center">
      <div className="d-flex gap-4 mx-5 flex-wrap align-items-center justify-content-center">
        <Button
          variant="link"
          className="text-danger fs-3"
          onClick={() => openPage(LINKS.maps)}
        >
          <FontAwesomeIcon icon={['fas', 'location-pin']} />
        </Button>

        <Button
          variant="link"
          className="text-danger fs-3"
          onClick={() => openPage(LINKS.mail)}
        >
          <FontAwesomeIcon icon={['fas', 'envelope']} />
        </Button>

        <Button
          variant="link"
          className="text-danger fs-3"
          onClick={() => openPage(LINKS.instagram)}
        >
          <FontAwesomeIcon icon={['fab', 'instagram']} />
        </Button>

        <Button
          variant="link"
          className="text-danger fs-3"
          onClick={() => openPage(LINKS.wpp)}
        >
          <FontAwesomeIcon icon={['fab', 'whatsapp']} />
        </Button>

        <Button
          variant="link"
          className="text-danger fs-3"
          onClick={() => openPage(LINKS.fb)}
        >
          <FontAwesomeIcon icon={['fab', 'facebook-f']} />
        </Button>
      </div>

      <p className="text-danger mt-2 mx-5">
        Copyright © 2023 - ExpoCond - Todos os Direitos Reservados.
      </p>
    </footer>
  )
}